import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Styles from "../styles/footer.module.scss"
import { Row, Col } from "react-bootstrap"

const FooterComponent = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFooterStore {
        edges {
          node {
            linkStore
            id
            logoStore {
              file {
                url
                fileName
              }
            }
          }
        }
      }
      allContentfulFooterColumnNames(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            id
            firstColName
            secondColName
            thirdColName
          }
        }
      }

      allContentfulFooterFirstLinkGroup(sort: { fields: createdAt, order: ASC }, filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            id
            title
            link
            createdAt
          }
        }
      }
      allContentfulFooterSecondLinkGroup(sort: { fields: createdAt, order: ASC }, filter: {node_locale: {eq: "en-US"}}){
        edges {
          node {
            id
            title
            link
            createdAt
          }
        }
      }
       contentfulFooterCopyright{
          copyright
        }
    }
  `)

  const logos = data.allContentfulFooterStore;
  const titles = data.allContentfulFooterColumnNames;
  const firstLInks = data.allContentfulFooterFirstLinkGroup;
  const secondLInks = data.allContentfulFooterSecondLinkGroup;

  return (
    <div>
      <div className={props.className}>
        <Row>
          <Col lg={6} sm={12} xs={12}>
            {titles.edges.map(title => {
              return <h6 className={Styles.h6} key={title.node.id}>{title.node.firstColName}</h6>
            })}

            {logos.edges.map(logo => {
              return <a href={logo?.node?.linkStore ? logo.node.linkStore : ''} key={logo.node.id} target="_blank" rel="noreferrer"><img className={Styles.footerImages} key={logo.node.id} src={logo?.node?.logoStore?.file?.url ? logo.node.logoStore.file.url : ''} alt={logo?.node?.fileName ? logo.node.fileName : ''} /></a>
            })}
          </Col>
          <Col lg={3} sm={6} xs={6}>
            {titles.edges.map(title => {
              return <h6 className={Styles.h6} key={title.node.id}>{title.node.secondColName}</h6>
            })}
            <div className={Styles.footerList}>
              <ul>
                {firstLInks.edges.map(link => {
                  return (
                    <li key={link.node.id}>
                      <a target="_blank" rel="noreferrer" href={link.node.link}>{link.node.title}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </Col>
          <Col lg={3} sm={6} xs={6}>
            {titles.edges.map(title => {
              return <h6 className={Styles.h6} key={title.node.id}>{title.node.thirdColName}</h6>
            })}
            <div className={Styles.footerList}>
              <ul>
                {secondLInks.edges.map(link => {
                  return (
                    <li key={link.node.id}>
                      <Link to={`/` + link.node.link}>{link.node.title}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      <p className={Styles.copyright}>{data.contentfulFooterCopyright.copyright}</p>
    </div>
  )
}

export default FooterComponent
