import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Navbar, NavDropdown, Nav, Button } from "react-bootstrap"
import HeaderStyles from "../styles/header.module.scss"
import DefaultButtonBlack from "../components/defaultButtonBlack"


const HeaderComponent = () => {
    const header = useStaticQuery(graphql`
    query {
            # Logo
            contentfulLogo {
                logoAlt
                logoLink
                logo {
                file {
                    url
                }
                }
            }
              contentfulHeaderCta{
                headeRCtaText
            }
            allContentfulFormGetStarted {
                edges {
                  node {
                    id
                    logo {
                      description
                      file {
                        url
                      }
                    }                                                            
                    logo {
                      id                     
                      file {
                        url
                      }
                    }
                  }
                }
              }
        }
    `)

    let Logo = header.contentfulLogo;
    let HeaderCta = header.contentfulHeaderCta;

    return (
        <header className={HeaderStyles.header}>
            <Navbar expand="md" className={HeaderStyles.navbar}>
                <Navbar.Brand to="/">
                    <Link to="/" className="navbar-brand">
                        <img
                            src={Logo.logo.file.url}
                            alt={Logo.logoAlt}
                            className={HeaderStyles.logo}
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="no-transition">
                    {header.allContentfulFormGetStarted.edges.map(item => {
                        return (
                            <img
                                key={item.node.id}
                                src={item?.node?.logo?.file?.url ? item.node.logo.file.url : ''}
                                alt={item.node.logo.description}
                                className={HeaderStyles.mobilLogo}
                            />
                        )
                    })}
                    <Nav className="mr-4">
                        <Nav.Link href="https://learn.stent.io/" target="_blank">Learn</Nav.Link>
                        <Nav.Link href="/employee-advocacy">Employee Advocacy</Nav.Link>
                        <Nav.Link href="/blog">Blog</Nav.Link>
                    </Nav>
                    <DefaultButtonBlack id="basic-nav-dropdown" title={HeaderCta.headeRCtaText} to="/form" />
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default HeaderComponent
