import React from "react"
import BtnStyle from "../styles/button.module.scss"
import { Link } from 'gatsby'

const DefaultButtonBlack = props => {
    // We can add custom padding and margin with props.padding, props.margin

    return (
        <Link
            className={BtnStyle.btnStentBlack}
            style={{ padding: props.padding, margin: props.margin }}
            to={props.to}>
            {props.title}
        </Link>
    )
}

export default DefaultButtonBlack
